import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import Grid from '../../components/Grid';
import Typography from '../../components/Typography';
import Breadcrumbs from '../../components/Breadcrumbs';

const localizations = [
  {
    locale: 'en',
    slug: '/contact/',
  },
];

const Contact = ({ location }) => (
  <Layout location={location} locale="nl-NL" localizations={localizations} slug="/nl/contact/">
    <Helmet
      title="Contact | Afosto"
      meta={[
        {
          name: 'description',
          content: 'Vragen over ons systeem? Neem contact op met Afosto!',
        },
      ]}
    >
      <html lang="nl" />
    </Helmet>
    <Grid container className="pt-24 pb-144">
      <Breadcrumbs location={location} className="mb-24" locale="nl-NL" />
      <Grid row>
        <Grid column xs={12} md={6}>
          <Typography variant="h1" className="mb-32">
            Contact
          </Typography>
          <Typography variant="h3" component="h2" className="mb-12">
            Bezoekadres
          </Typography>
          <Typography variant="body-large" className="mb-32">
            Kieler Bocht 15 C
            <br />
            9723 JA Groningen
            <br />
            050 711 95 19
          </Typography>
          <Typography variant="h3" component="h2" className="mb-12">
            Sales
          </Typography>
          <Typography variant="body-large" className="mb-32">
            sales@afosto.com
          </Typography>
          <Typography variant="h3" component="h2" className="mb-12">
            Support
          </Typography>
          <Typography variant="body-large" className="mb-32">
            support@afosto.com
          </Typography>
          <Typography variant="h3" component="h2" className="mb-12">
            Bedrijfsgegevens
          </Typography>
          <Typography variant="body-large" className="mb-32">
            Afosto Saas BV
            <br />
            KvK: 61564702 te Groningen
            <br />
            BTW nummer: NL854393389B01
            <br />
            Rabobank: 192 0635 37
            <br />
            IBAN: NL76 RABO 0192 0635 37
            <br />
            BIC: RABONL2U
          </Typography>
        </Grid>
        <Grid column xs={12} md={6}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2389.095506825166!2d6.607254751713783!3d53.21613589205854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c9cd32469ea8b7%3A0xca93a32272278d52!2sAfosto!5e0!3m2!1snl!2snl!4v1583247645300!5m2!1snl!2snl"
            width="100%"
            height="100%"
            frameBorder="0"
            className="bg-gray-100"
            style={{ border: 0, minHeight: 300 }}
            allowFullScreen=""
            title="Afosto kantoor kaart"
          />
        </Grid>
      </Grid>
    </Grid>
  </Layout>
);

Contact.propTypes = {
  location: PropTypes.object,
};

Contact.defaultProps = {
  location: undefined,
};

export default Contact;
